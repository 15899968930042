import amnt from './amnt';

export default {
    app: {
        yes: 'yes',
        no: 'no',

        content_loading: 'loading...',

        recipient: 'recipient | recipients',
        unit: 'unit | units',
        community: 'community | communities',
        group: 'group | groups',
        item: 'item | items',
    },

    validation: {
        required: 'Required',
        email: 'Invalid email address',
        max_length: 'Must be {max} characters or less',
        min_length: 'Must be {min} characters or more',
        max_array_length: 'Must be {max} items or less',
        min_array_length: 'Must be {min} items or more',
        alphanumeric: 'Only alphanumeric characters',
        phone: 'Invalid phone number',
        // us_phone: "Phone number must be 11 digits and start with '+' sign", // '+' sign is not visible in phone mask
        us_phone: 'Phone number must be 11 digits',
        no_spaces: 'Field cannot contain spaces',
        selected: 'At least one should be selected',
        safe_url: 'Url must start with http or https',
        number: 'Must be a number',
        natural: 'Cannot contain decimal part',
        min_value: 'Minimum value is {min}',
        max_value: 'Maximum value is {max}',
        min_datetime: 'Value must be after {min}',
        max_datetime: 'Value must be before {max}',
        min_duration: 'Minimum duration is {hours}h {minutes}m',
        max_duration: 'Maximum duration is {hours}h {minutes}m',
        max_decimal_places: 'Maximum decimal places is {max}',
        invalid_value: 'Invalid value',
        invalid_schedule: 'Schedule conflict',
        invalid_time: 'Time conflict',
        equal_time: "Open & close time can't be the same",
        empty_schedule: 'At least one day should be available for reservations',
        cognitoUsername: 'Username must ba a valid email or phone number',
    },

    calendar: {
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        months_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        days_short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    },

    amnt,

    sreq: {
        feed: {
            header: {
                status: 'status:',
                scheduled_for: 'scheduled for:',
                assigned_to: 'assigned to:',
            },

            filter: {
                all: 'all',
                chat: 'chat',
                notes: 'notes',
                history: 'history',
                clear_all_action: 'clear all',
                note_ordinary_internal: 'ordinary notes (internal)',
                note_ordinary_public: 'ordinary notes (public)',
                note_cost_internal: 'cost allocation (internal)',
                note_cost_public: 'cost allocation (public)',
            },

            composer: {
                header_text: 'You are sending a {0}',
                note: 'note',
                chat: 'chat',
                ordinary: 'ordinary',
                cost_allocation: 'cost allocation',
                public: 'public',
                internal: 'internal',
                charge_resident: 'charge resident',
                dont_charge_resident: 'don’t charge resident',
                cancel_request: 'cancel request',
                recurring_payment: 'recurring payment?',
                start_date: 'start date',
                due_date: 'due date',
                total_cost: 'total cost',
                number_of_months: 'number of months to repay',
                placeholder: 'message...',
                restriction: {
                    STATUS: 'Impossible to create {messageType} in the current request status',
                    NO_RESIDENT: 'Impossible to create {messageType}, as there is no Resident',

                    type: {
                        SIMPLE: 'chat message',
                        SIMPLE_PUBLIC: 'chat message',
                        NOTE_ORDINARY: 'ordinary note',
                        NOTE_ORDINARY_PUBLIC: 'ordinary note',
                        NOTE_COST_ALLOCATION: 'cost allocation note',
                        NOTE_COST_ALLOCATION_PUBLIC: 'cost allocation note, charging a Resident',
                    },
                },
            },

            message: {
                system: 'system',
                triggered_by: 'by {name}',
                cancelation_reason: 'cancelation reason',
                ordinary_note: 'ordinary note',
                cost_allocation_note: 'cost allocation note',
                public: 'public',
                only_internal: 'only internal',
                charge_resident: 'charge resident',
                dont_charge_resident: 'don’t charge resident',
                recurring_payment: 'recurring payment',
                one_time_payment: 'one-time payment',
                start_date: 'start date',
                due_date: 'due date',
                total_cost: 'total cost',
                number_of_months: '# of months to repay',

                retry_action: 're-try',
                delete_action: 'delete',
            },

            timezone_warning: 'All dates and times are displayed according to the Community time zone.',
            unread_messages: 'unread messages',
        },

        status: {
            NEW: 'Received',
            ASSIGNED: 'Assigned',
            SCHEDULED: 'Scheduled',
            WIP: 'In Progress',
            PM_VALIDATION: 'Waiting PM Validation',
            RESIDENT_VALIDATION: 'Waiting Resident Validation',
            COMPLETED: 'Completed',
            CANCELED: 'Canceled',
        },
    },

    cbr: {
        structure_types: {
            BUILDING: 'building',
        },

        unit: {
            statuses: {
                RENTED: 'Rented',
                VACANT_NOT_LEASED: 'Vacant - not leased',
                VACANT_LEASED: 'Vacant - leased',
                NTV_AVAILABLE: 'NTV - available',
                NTV_LEASED: 'NTV - leased',
                MODEL: 'Model',
                DOWN: 'Down',
                REHAB: 'Rehab',
                SHOP: 'Shop',
            },
        },

        lease: {
            statuses: {
                CURRENT: 'Current',
                FUTURE: 'Future',
            },
        },
    },

    ri: {
        asset: 'asset | assets',
        asset_deleted: 'was deleted | were deleted',
        reservation: 'reservation | reservations',
        periods: {
            HOUR: 'hour | hours',
            DAY: 'day | days',
            WEEK: 'week | weeks',
            MONTH: 'month | months',
        },
        unit_building: 'Unit {unitName}, Building {buildingName}',
        unit_apartment_building: 'Unit {unitName}, Apartment {apartmentName}, Building {buildingName}',
        invalid_profile: 'The reservation can’t be made in relation to the selected user ({username})',
        invalid_start_date: '{date} isn’t available any more. Please select new start day',
        invalid_start_datetime: '{datetime} isn’t available any more. Please select new start time',
        invalid_assets:
            '{count} from {total} assets selected isn’t available any more | {count} from {total} assets selected aren’t available any more',
        restricted_duration: 'Selected duration is restricted due to availability schedule',
        reduced_reservation_period:
            'please, note that your reservation period is less than {period} due to availability schedule of selected asset. Asset should be returned before community closes during the last day. To return it later - set another reservation period',
    },
};
